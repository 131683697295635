<template>
  <div>
    <BasicLogo width="300" />
    <div class="content-title">
      <BasicTitle title="Solicite Acceso para Administradora" />
      <BasicSubtitle
        subtitle="Los campos señalados con (*) son obligatorios."
      />
    </div>
    <div class="content-form">
      <BorderInput v-model="access.name" label="Nombre de Administradora *" />
      <FormError :show="accessError.name" message="Ingrese un nombre" />
      <BorderInput v-model="access.email" label="Email *" />
      <FormError :show="accessError.email" message="Ingrese un email válido" />
      <div class="pd-flex">
        <BorderInputPrefix label="+598" />
        <BorderInputPhone v-model="access.phone" label="Teléfono *" />
      </div>
      <FormError
        :show="accessError.phone"
        message="Ingrese un teléfono válido"
      />
      <BorderSelect
        v-model="access.plan"
        label="Tipo de Plan"
        :options="store.state.general.plans"
      />
      <FormError :show="accessError.plan" />
    </div>
    <div class="content-submit">
      <PrimaryButton label="Solicitar" :click="onAccess" />
    </div>
  </div>
</template>

<script>
import BasicLogo from "../../widgets/logo/BasicLogo";
import BorderInput from "../../widgets/input/BorderInput.vue";
import BorderInputPrefix from "../../widgets/input/BorderInputPrefix.vue";
import BorderInputPhone from "../../widgets/input/BorderInputPhone.vue";
import BorderSelect from "../../widgets/select/BorderSelect.vue";
import PrimaryButton from "../../widgets/button/PrimaryButton.vue";
import BasicTitle from "../../widgets/title/BasicTitle.vue";
import FormError from "../../widgets/error/FormError.vue";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle.vue";
import store from "../../store";
import { reactive, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { validation } from "../../constants";

export default {
  components: {
    BasicLogo,
    BorderInput,
    BorderInputPrefix,
    BorderInputPhone,
    BorderSelect,
    PrimaryButton,
    BasicTitle,
    FormError,
    BasicSubtitle,
  },
  setup() {
    const router = useRouter();
    const access = reactive({
      name: "",
      email: "",
      phone: "",
      plan: "",
    });
    const accessError = reactive({
      name: false,
      email: false,
      phone: false,
      plan: false,
    });

    watchEffect(() => {
      if (access.name) accessError.name = false;
      if (access.email && validation.emailRegex.test(access.email))
        accessError.email = false;
      if (access.phone && validation.phoneRegex.test(access.phone))
        accessError.phone = false;
      if (access.plan) accessError.plan = false;
    });

    const validate = () => {
      let valid = true;

      if (!access.name) {
        accessError.name = true;
        valid = false;
      }
      if (!access.email || !validation.emailRegex.test(access.email)) {
        accessError.email = true;
        valid = false;
      }
      if (!access.phone || !validation.phoneRegex.test(access.phone)) {
        accessError.phone = true;
        valid = false;
      }

      return valid;
    };

    const onAccess = async () => {
      if (!validate()) return;

      if (access.phone) {
        access.phone = `+598 ${access.phone}`;
      }

      if (access.plan) {
        const finded = store.state.general.plans.find(
          (item) => item.id === access.plan
        );
        access.plan = finded.name;
      }

      store.commit("setLoading", true);
      const response = await store.dispatch("AUTH_ACCESS", access);
      store.commit("setLoading", false);
      if (response.ok) {
        store.state.toast.add({
          severity: "success",
          summary: "",
          detail: response.message,
          life: 5000,
        });
        router.push("/login");
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    return { store, access, accessError, onAccess };
  },
};
</script>

<style scoped>
.content-title {
  margin-top: 4rem;
}
.content-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
}
.content-submit {
  display: flex;
  justify-content: center;
  margin: 2rem 0 4rem;
}
</style>
